import { createSlice } from '@reduxjs/toolkit';
import { deleteCookie } from '../../utils/utilFunctions';
import { ARM_TOKEN_COOKIE, removeLocalStorageAuth } from '../../utils/environmentUtils';

const initialState = {
	isLoggedIn: false,
	accountSource: null
};

export const loginSlice = createSlice({
	name: 'loginState',
	initialState,
	reducers: {
		logIn: (state) => {
			state.isLoggedIn = true;
		},
		logOut: (state) => {
			//Remove this as well to delete the old auth storage
			removeLocalStorageAuth(true);
			sessionStorage.removeItem('persist:sideNav');
			deleteCookie(ARM_TOKEN_COOKIE);
			state.isLoggedIn = false;
			state.user = null;
			state.access_token = null;
			state.refreshToken = null;
			state.accountSource = null;
		},
		setUser: (state, action) => {
			state.user = action.payload;
		},
		setNewToken: (state, action) => {
			state.access_token = action.payload.access_token;
		},
		setAccountSource: (state, action) => {
			state.accountSource = action.payload.accountSource;
		}
	}
});

// Action creators are generated for each case reducer function
export const { logIn, logOut, setUser, setNewToken, setAccountSource } = loginSlice.actions;

export default loginSlice.reducer;
