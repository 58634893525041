import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { TextField, FormHelperText } from '@mui/material';
import SignatureCanvas from 'react-signature-canvas';

import ComponentHeader from './component-header';
import ComponentLabel from './component-label';
import { getElementIcon, stripTextHTML } from '../../FormBuilder/utils';

import StyledWrapper from './style';

const Signature = forwardRef(
	(
		{ defaultValue = '', hasError, editModeOn, read_only, data, index, mutable, _onDestroy },
		ref
	) => {
		const [canvasDataURL, setCanvasDataURL] = useState(defaultValue);
		const [isCleared, setIsCleared] = useState(!read_only);
		const [error] = useState(hasError || false);

		useImperativeHandle(ref, () => ({
			getCanvasDataURL: () => canvasDataURL
		}));

		const signatureCanvasRef = useRef(null);

		const triggerFormBlur = () => {
			setTimeout(() => {
				const triggerBlur = document.getElementById('hiddenBlurTriggerInput');
				triggerBlur?.click();
			}, 1000);
		};

		const clearData = () => {
			setCanvasDataURL('');
			setIsCleared(true);
			triggerFormBlur();
		};

		const saveData = () => {
			setCanvasDataURL(signatureCanvasRef.current?.toDataURL());
			setIsCleared(false);
			triggerFormBlur();
		};

		const errorMessage = error?.error ? `${data.label} ${error.error}` : '';

		return (
			<div className="SortableItem rfb-item alwaysbreak">
				<ComponentHeader
					data={data}
					index={index}
					editModeOn={editModeOn}
					mutable={mutable}
					_onDestroy={_onDestroy}
				/>

				{index >= 0 ? (
					<>
						<StyledWrapper.CustomLabel sx={{ fontSize: '22px' }} disabled={read_only}>
							<>
								{getElementIcon(data?.element)}
								{stripTextHTML(data?.label)}
								{data?.unique_identifier && <div>{`(ff-${data?.unique_identifier})`}</div>}
								{data?.required && <StyledWrapper.AsteriskSpan> *</StyledWrapper.AsteriskSpan>}
							</>
						</StyledWrapper.CustomLabel>
						<TextField
							disabled={true}
							style={{ width: '100%', border: '1px solid rgb(164, 177, 184)', borderRadius: '5px' }}
							multiline
							rows={4}
							InputLabelProps={{ shrink: true }}
						/>
					</>
				) : (
					<>
						<StyledWrapper.BorderOutline error={error} src={data.src} disabled={read_only}>
							<div className="form-group signature-form-group" name={data.field_name}>
								<ComponentLabel data={data} read_only={read_only} />

								{read_only || (canvasDataURL && !isCleared) ? (
									<img src={canvasDataURL} />
								) : (
									<SignatureCanvas
										onEnd={saveData}
										defaultValue={canvasDataURL}
										ref={signatureCanvasRef}
									/>
								)}

								{!read_only && (
									<i className="fas fa-times" onClick={clearData} title="Clear Signature"></i>
								)}

								<input type="hidden" name={data.field_name} defaultValue={canvasDataURL} />
							</div>
						</StyledWrapper.BorderOutline>
					</>
				)}

				<StyledWrapper.FormHelperTextContainer>
					<FormHelperText error={error}>{errorMessage}</FormHelperText>

					<FormHelperText>{data?.help_message}</FormHelperText>
				</StyledWrapper.FormHelperTextContainer>
			</div>
		);
	}
);

export default Signature;
