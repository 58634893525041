import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import './app.css';

import DemoBar from './demobar';
import * as variables from './variables';
import './FormBuilder.css';
import { items } from './ToolboxSchema';
import FormElementsEdit from './form-elements-edit';

import AppForm from '../../pages/IndividualReview/components/AppForm/AppForm';
import { ReactFormBuilder } from '../FormBuilderLibrary';
import { setFormSchema } from '../FormBuilderLibrary/slice';
import { StageAppSelection } from '../../pages/StageCreationModal/components/StageAppSelection/StageAppSelection';
import { usePermissions } from '../../hooks/usePermissions';

const App = ({ props }) => {
	const _ = require('lodash');
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();

	const url = window.location.href;
	const isAppProcess = url?.includes('application-setup');
	const isReview = searchParams.get('formBuilder') === 'review';

	const { formId, formInfo } = props;
	const formSchema = useSelector((state) => state.form.formSchema[0]);
	const isReadOnly = useSelector((state) => state.form.isReadOnly);
	const isReadyToPublish = props.isReadyToPublish ?? false;
	const [data, setData] = useState([]);
	const [refresh, setRefresh] = useState(false);
	const [readOnly, setReadOnly] = useState(isReadOnly);
	const { hasEditPermissions } = usePermissions();
	const onLoad = async () => {
		try {
			let response = formSchema ? Object.values(formSchema) : [];
			setData(response || props?.formSchema);
			setRefresh(!refresh);
			let clonedResponse = _.cloneDeep(response || props?.formSchema);
			return clonedResponse;
		} catch (e) {
			console.log(e);
		}
	};

	const onPost = (data) => {
		let newData = _.cloneDeep(data.task_data);
		newData?.forEach((e) => {
			delete e.component;
			e.field_name === undefined &&
				(e.field_name = `${e.group_name.toLowerCase()}_${e.id.toLowerCase()}`);
		});

		dispatch(setFormSchema([0, [...newData]]));
	};

	useEffect(() => {
		const mainAppContainer = document.getElementById('baselayout-box');
		const toolboxContainer = document.getElementById('react-form-builder-toolbar');

		mainAppContainer.addEventListener('scroll', function () {
			const isFixed = toolboxContainer?.classList?.contains('fixedElement');
			mainAppContainer?.scrollTop >= 235
				? toolboxContainer?.classList?.add('fixedElement')
				: isFixed && toolboxContainer?.classList?.remove('fixedElement');
		});
	}, []);

	useEffect(() => {
		setReadOnly(props?.isMasterLayout ? true : isReadOnly);
	}, [isReadOnly]);

	useEffect(() => {
		onLoad();
	}, [formSchema]);
	return (
		<div data-testid={'formbuilder'} style={{ width: '100%' }} className="App">
			{!props?.hidePreviewBar && (
				<DemoBar
					data={data}
					variables={variables}
					formID={formId}
					isReadyToPublish={props?.isReadyToPublish}
					handleModalClose={props?.handleModalClose}
					forceRefresh={() => setRefresh(!refresh)}
					hidePreviewButton={props?.hidePreviewButton}
					readOnly={readOnly}
					totalForms={props?.totalForms}
					in_use={props?.in_use}
					isTemplate={props?.isTemplate}
					hasEditPermissions={hasEditPermissions}
					setMetaInfoChanged={props?.setMetaInfoChanged}
					programStatus={props.programStatus}
				/>
			)}

			<div style={{ display: 'flex', gap: '20px', width: '100%' }}>
				{isReview && isAppProcess && (
					<StageAppSelection isReviewForm={true} isReadOnly={readOnly} />
				)}

				<ReactFormBuilder
					onLoad={onLoad}
					onPost={onPost}
					refresh={refresh}
					forceRefresh={() => setRefresh(!refresh)}
					toolbarItems={items}
					formId={formId}
					formInfo={formInfo}
					renderEditForm={(props) => <FormElementsEdit {...props} />}
					readOnly={readOnly}
					in_use={false}
					enableAddOnClick={props?.enableAddOnClick}
					hideToolbox={props?.hideToolbox}
					onlyInstructions={props?.onlyInstructions}
					staticInstructions={props?.staticInstructions}
					formTitle={props?.formTitle}
					preSave={props?.preSave}
					isMasterLayout={props?.isMasterLayout}
					setMetaInfoChanged={props?.setMetaInfoChanged}
					isTemplate={props?.isTemplate}
					hasEditPermissions={hasEditPermissions}
					isReadyToPublish={isReadyToPublish}
				/>
			</div>
		</div>
	);
};

export const FormBuilder = (props) => {
	return (
		<>
			<Helmet>
				<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css" />
				<link
					rel="stylesheet"
					href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
					integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
					crossOrigin="anonymous"
				/>
			</Helmet>
			<App props={props} />
		</>
	);
};
