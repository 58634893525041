export const FORM_EDIT_PUBLISHED_WARNING_TITLE = 'Editing a Published Program';

export const FORM_EDIT_READY_TO_PUBLISH_WARNING_TITLE = 'Editing a Ready to Publish Program';

export const FORM_EDIT_PUBLISHED_WARNING_MESSAGE =
	'This program has been marked for publication, and you might have submissions that have already been made against this form. Please note the following:';

export const FORM_EDIT_READY_TO_PUBLISH_WARNING_MESSAGE =
	'This program has been marked Ready for Publish, and you might have submissions that have already been made against this form. Please note the following:';

export const FORM_EDIT_WARNING_LIST = [
	'If you add new fields, previous submissions will not have values populated for those fields',
	'If you edit fields on the form, you may affect the submission made by users who have already completed a submission against this form',
	'If you delete a field, all submissions that completed this field will have their entered data permanently deleted.'
];

export const FORM_EDIT_WARNING_CONFIRMATION_MESSAGE =
	'Are you sure you want to continue editing this form?';

export const FORM_ADDITION_READY_TO_PUBLISH_WARNING_MESSAGE =
	'This program has been marked as Ready for Publish, you might have submissions that have already been made. Please note the following:';

export const FORM_ADDITION_PUBLISHED_WARNING_MESSAGE =
	'This program has been marked for publication, you might have submissions that have already been made. Please note the following:';

export const FORM_ADDITION_WARNING_LIST = [
	'If you create a form and a submission has already progressed pass the new stage or form, you will need to reopen the submission and request the user to return to their application and complete the newly added form. Users will not be automatically notified that the application process has changed.'
];

export const FORM_ADDITION_READY_TO_PUBLISH_WARNING_TITLE = 'Editing a Ready to Publish Program';

export const FORM_ADDITION_PUBLISHED_WARNING_TITLE = 'Editing a Published Program';

export const ASSIGN_REVIEWER = 'Reviewer has been assigned.';

export const MARK_APPLICATION_AS_READY_TO_PUBLISH =
	'This solicitation has been marked for publication. Return to the solicitation in Grants Network and click "Publish" once you are ready to publish your solicitation';

export const FORM_ADDITION_CONFIRMATION_MESSAGE = 'Would you like to continue adding a Form?';

export const FORM_DELETION_WARNING_TITLE =
	'This program has been marked Ready for Publish, you might have submissions that have already been made. Please note the following:';

export const FORM_DELETION_WARNING_MESSAGE =
	'If you delete a Form, the data users have submitted against the Form will be permanently deleted and will no longer be associated with their application submission.';

export const SETUP_APP_SAVE_ERR_MSG =
	'This application process cannot be saved as it is missing either the Application Process Name or Application Process Instructions. Please verify and try again';

export const SETUP_STAGE_SAVE_ERR_MSG =
	'This stage cannot be saved as it is missing either the Stage Name or Stage Instructions. Please verify and try again';

export const SETUP_FORM_SAVE_ERR_MSG =
	'This form cannot be saved as it is missing either the Form Name, Form Instructions, or the form does not contain any form elements (fields). Please verify and try again';

export const TEMP_APP_SAVE_ERR_MSG =
	'This application process template cannot be saved as it is missing either the Application Process Template Name or Application Process Template Instructions. Please verify and try again';

export const TEMP_STAGE_SAVE_ERR_MSG =
	'This stage template cannot be saved as it is missing either the Stage Template Name or Stage Template Instructions. Please verify and try again';

export const TEMP_FORM_SAVE_ERR_MSG =
	'This form template cannot be saved as it is missing either the Form Template Name, Form Template Instructions or the template does not contain any form elements (fields). Please verify and try again';
