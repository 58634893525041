import { useEffect, useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/dist/query';

import StyledWrapper from './style';

import { ItemPageTitle } from '../../components/ItemPageTitle';
import { CustomModal } from '../../components/Modal';
import BackDialog from '../../components/BackDialog/BackDialog';
import { TabPanel, a11yProps } from '../../components/TabPanel';
import { FormBuilder } from '../../components/FormBuilder';
import { useBuilderPreviewButton } from '../../hooks/useBuilderPreviewButton';
import {
	setFormHasChanged,
	setFormMetaInfo,
	setFormReadOnly,
	setFormSchema
} from '../../components/FormBuilderLibrary/slice';
import { compareMultiLevelArray, getEntityPrefix } from '../../utils/utilFunctions';
import { getLocalAuth } from '../../utils/environmentUtils';
import {
	useGetIndividualFormSchemaQuery,
	useGetIndividualFormsQuery
} from '../../services/endpoints/formBuilderEndpoints';
import { StageAppSelection } from '../StageCreationModal/components/StageAppSelection/StageAppSelection';
import ReviewForm from '../IndividualReview/components/ReviewForm/ReviewForm';

import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { MultiFormViewer } from '../../components/MultiFormViewer';
import AppFormIcon from '../../assets/customIcons/appFormIcon';
import ReviewFormIcon from '../../assets/customIcons/reviewFormIcon';
import { TemplateUsage } from '../StageTemplateCreationModal/TemplateUsage';
import { SimulateSaveButton } from '../../components/SimulateSaveButton/SimulateSaveButton';
import { usePermissions } from '../../hooks/usePermissions';
import { saveVariable } from '../../components/BaseLayout/slice';

export const FormBuilderModal = ({
	openModal,
	closeModal,
	totalForms,
	isTemplate,
	programStatus,
	isReadyToPublish
}) => {
	const tabs = ['SETUP FORM', 'PREVIEW FORM'];
	isTemplate && tabs?.push('USAGE');
	const [searchParams, setSearchParams] = useSearchParams();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isAppSetup = window.location.pathname?.includes('application-setup');
	const previousPath = useSelector((state) => state.globalProgramVariables?.previousPath);
	const formDependancies = useSelector((state) => state.form.dependancies);
	const formMetaInfo = useSelector((state) => state.form.formMetaInfo);
	const reduxSchema = useSelector((state) => state.form.formSchema);
	const formHasChanged = useSelector((state) => state.form.hasChanged);
	const isReadOnly = useSelector((state) => state.form.isReadOnly);
	const programInfo = useSelector((state) => state.globalProgramVariables.program);
	const accountSource = useSelector((state) => state?.login?.accountSource);
	const answers = useSelector((state) => state?.form?.form);
	const apiToken = getLocalAuth(accountSource)?.access_token;
	const { state: previewButtonState, previewOnClick } = useBuilderPreviewButton(true);
	const [alertBalloon, setAlertBalloon] = useState({ isOpen: false });
	const [apiSchema, setApiSchema] = useState();
	const [schemaState, setSchemaState] = useState([]);
	const [outerLoading, setOuterLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [value, setValue] = useState(0);
	const [backModal, setBackModal] = useState(false);
	const [preSave, setPreSave] = useState();
	const [MetaInfoChanged, setMetaInfoChanged] = useState(false);
	const [readOnly, setReadOnly] = useState(isReadOnly || false);
	const [readOnlyTest, setReadOnlyTest] = useState(false);
	const [readOnlyReset, setReadOnlyReset] = useState(true);
	const formBuilderParam = searchParams.get('formBuilder');
	const formID = searchParams?.get('formId') == 'undefined' ? null : searchParams?.get('formId');
	const { hasEditPermissions } = usePermissions();
	const apiOptions = {
		id: formID || '',
		apiToken: apiToken
	};
	const {
		data: apiFormData,
		isLoading,
		error: formApiError
	} = useGetIndividualFormSchemaQuery(formID ? apiOptions : skipToken, {
		refetchOnMountOrArgChange: true
	});
	const { data: individualFormData } = useGetIndividualFormsQuery(formID ? apiOptions : skipToken);
	const handleClose = () => {
		setSearchParams(searchParams.delete('formBuilder'));
		closeModal && closeModal();
		setOpen(false);
	};

	const handleCloseModal = () => {
		if (MetaInfoChanged || formHasChanged) {
			setBackModal({ isOpen: true });
		} else if (previousPath) {
			const { pathname, search } = previousPath;
			dispatch(saveVariable(['previousPath', null]));
			navigate(`${pathname}${search}`);
		} else {
			handleClose();
		}
	};

	const handleChange = (event, newValue) => {
		searchParams.set('viewType', newValue === 0 ? 'builder' : newValue === 1 ? 'preview' : 'usage');
		setSearchParams(searchParams);
		setReadOnlyTest(false);
	};

	const getPageTitle = () => {
		const formIcon =
			searchParams.get('formBuilder') === 'application' ? (
				<AppFormIcon isTemplate={isTemplate} isWarning={isTemplate} />
			) : (
				<ReviewFormIcon isTemplate={isTemplate} isWarning={isTemplate} width={'20px'} />
			);
		const formType = searchParams.get('formBuilder') === 'application' ? 'Application' : 'Review';
		const pageTitle = (
			<div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
				{`${formType} Form`}
				{isTemplate && <span style={{ color: '#ED6C02' }}>Template</span>}
				{`: ${formMetaInfo?.label !== '' && formMetaInfo?.label ? `${formMetaInfo?.label}` : ''} ${
					formMetaInfo?.form_id ? `[${formMetaInfo?.form_id}]` : ''
				}`}
			</div>
		);
		return pageTitle;
	};

	useEffect(() => {
		if (answers.length > 0) {
			setReadOnlyReset(false);
		} else {
			setReadOnlyReset(true);
		}
	}, [answers]);

	useEffect(() => {
		if (formID && formID !== 'undefined' && formBuilderParam && formApiError?.status) {
			navigate('/error');
		}
	}, [formApiError]);

	useEffect(() => {
		const formattedFormData = {
			...individualFormData,
			form_id:
				individualFormData?.unique_identifier &&
				`${getEntityPrefix({
					type:
						individualFormData?.form_type === 'application' ? 'application-form' : 'review-form',
					isMaters: !isTemplate,
					isBaseTemplate: isTemplate,
					isAdHoc: individualFormData?.is_adhoc
				})}${individualFormData?.unique_identifier}`,
			program: individualFormData?.program,
			project_title: programInfo?.title,
			stage: individualFormData?.stage
		};
		dispatch(setFormMetaInfo(formattedFormData));
	}, [programInfo, individualFormData]);

	useEffect(() => {
		dispatch(setFormReadOnly(formID === 'undefined' || !formID ? false : true));
		dispatch(setFormSchema([-1, [apiFormData]]));
		apiFormData && setApiSchema(apiFormData);
	}, [apiFormData, isLoading]);

	useEffect(() => {
		const reload = () => {
			setOuterLoading(true);
			setTimeout(function () {
				setOuterLoading(false);
			}, 1000);
		};

		(apiSchema || formApiError) && reload();
		(!formID || formID === 'undefined') && reload();
	}, [apiSchema, formApiError]);

	useEffect(() => {
		setReadOnly(isReadOnly);
	}, [isReadOnly]);

	useEffect(() => {
		const isSame = compareMultiLevelArray([apiFormData], reduxSchema);
		dispatch(setFormHasChanged(!isSame || MetaInfoChanged));
	}, [schemaState, reduxSchema, formMetaInfo]);

	useEffect(() => {
		if (formBuilderParam) {
			searchParams.delete('stageCreation');
			setSearchParams(searchParams);
			setOpen(true);
		} else {
			handleClose();
		}
	}, [openModal, searchParams]);

	useEffect(() => {
		const subPage = searchParams.get('viewType');
		switch (subPage) {
			case 'builder':
				setValue(0);
				return;
			case 'preview':
				setValue(1);
				setPreSave({
					formMetaInfo: formMetaInfo,
					schema: reduxSchema
				});
				return;
			case 'usage':
			case 'linkedTo':
			case 'appliedTo':
				setValue(2);
				return;
			default:
				setValue(0);
		}
	}, [location, isLoading, readOnly, apiFormData]);

	return (
		<CustomModal
			width={'100%'}
			height={'100%'}
			isOpen={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<StyledWrapper.OutermostContainer>
				<StyledWrapper.BackIconContainer>
					<IconButton
						onClick={handleCloseModal}
						aria-label="delete"
						size="large"
						style={{ fontSize: '26px', background: '#E0E0E0' }}
					>
						<KeyboardBackspaceIcon style={{ fontSize: '26px' }} />
					</IconButton>
				</StyledWrapper.BackIconContainer>
				<ItemPageTitle removeGrayLine text={getPageTitle() || ''} isUnsaved={formHasChanged} />

				{outerLoading || isLoading ? (
					<>
						<Box
							sx={{
								display: 'flex',
								width: '100%',
								height: '100%',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<CircularProgress />
						</Box>
					</>
				) : (
					<StyledWrapper.InnerContainer padding={'0px 55px'} margin={'40px 0px 0px 0px'}>
						<Box sx={{ width: '100%' }}>
							<Box
								data-testid={`individualReview-box`}
								sx={{ padding: '0px 24px', marginBottom: '30px' }}
							>
								<Tabs
									value={value}
									onChange={(e, value) => {
										value === 1 ? previewOnClick() : handleChange(e, value);
									}}
									aria-label="basic tabs example"
									style={{ width: 'fit-content' }}
								>
									{tabs.map((e, index) => {
										const disabled =
											(e === 'PREVIEW FORM' && previewButtonState) ||
											(e === 'USAGE' && !isReadOnly);
										return (
											<Tab
												disabled={disabled}
												iconPosition="start"
												data-testid={`individualReview-tab-${index}`}
												key={`FormTab-${index}`}
												label={`${e}`}
												{...a11yProps(e + 1)}
											/>
										);
									})}
								</Tabs>
							</Box>
							<TabPanel data-testid={`individualReview-tab-panel`} value={value} index={value}>
								{value === 0 ? (
									<FormBuilder
										in_use={formMetaInfo?.in_use || formMetaInfo?.is_archived}
										preSave={preSave}
										readOnly={readOnly || !hasEditPermissions}
										formSchema={preSave?.schema?.[0] || apiFormData}
										isReadyToPublish={isReadyToPublish}
										data={preSave?.schema || schemaState}
										handleModalClose={handleClose}
										formInfo={{ title: 'test', hasBusinessLogic: formDependancies?.length > 0 }}
										saveData={(schema) => {
											setSchemaState(schema);
										}}
										formId={searchParams?.get('formId') || ''}
										hidePreviewButton={true}
										totalForms={totalForms}
										setMetaInfoChanged={setMetaInfoChanged}
										isTemplate={isTemplate}
										programStatus={programStatus}
									/>
								) : value === 1 ? (
									searchParams.get('formBuilder') === 'application' ? (
										<MultiFormViewer
											marginBottom={'20px'}
											disableActionButtons
											disableFooterButtons
											isSimulating={true}
											setReadOnly={setReadOnly}
										/>
									) : (
										<>
											<SimulateSaveButton
												marginBottom={'40px'}
												readOnly={readOnly}
												setReadOnly={setReadOnly}
												setOuterLoadingCancel={setOuterLoading}
												readOnlyTest={readOnlyTest}
												readOnlyReset={readOnlyReset}
												setReadOnlyReset={setReadOnlyReset}
												setReadOnlyTest={setReadOnlyTest}
												setAlertBalloon={setAlertBalloon}
												formMetaInfo={formMetaInfo}
											/>
											<StyledWrapper.InnerContainer>
												<StageAppSelection
													customWidth={'50%'}
													isReviewForm={true}
													isReadOnly={readOnly || !hasEditPermissions}
												/>
												<ReviewForm
													isAppSetup={isAppSetup}
													reduxSchema
													reviewer={'test'}
													readOnly={false}
													disableActionButtons
													setReadOnlyTest={setReadOnlyTest}
													setReadOnly={setReadOnly}
												/>
											</StyledWrapper.InnerContainer>
										</>
									)
								) : (
									<TemplateUsage isForm />
								)}
							</TabPanel>
						</Box>
					</StyledWrapper.InnerContainer>
				)}
				{backModal && (MetaInfoChanged || formHasChanged) && (
					<BackDialog
						disableBackdropClick
						isOpen={backModal?.isOpen}
						title={'asd'}
						handleClose={handleClose}
						handleStay={() => setBackModal({ isOpen: false })}
					/>
				)}
			</StyledWrapper.OutermostContainer>
		</CustomModal>
	);
};
