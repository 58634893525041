import { useState } from 'react';
import { useDispatch } from 'react-redux';

import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import { clearValues } from '../FormBuilderLibrary/slice';
import SaveDialog from '../SaveDialog/SaveDialog';

import { exportFormToPDF } from '../../utils/utilFunctions';

export const SimulateSaveButton = ({
	marginBottom,
	setOuterLoadingCancel,
	readOnlyTest,
	setReadOnlyTest,
	readOnlyReset,
	setReadOnlyReset,
	setReadOnly,
	setAlertBalloon,
	formMetaInfo,
	applicationId
}) => {
	const dispatch = useDispatch();
	const [loadingCancel, setLoadingCancel] = useState(false);
	const [saveModal, setSaveModal] = useState(false);

	return (
		<>
			<div
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
					gap: '15px',
					marginBottom: marginBottom
				}}
			>
				<p
					style={{
						color: '#ED6C02',
						marginRight: 'auto',
						marginBottom: '0px',
						fontSize: '14px'
					}}
				>
					You can test your form, fill it with content, see if business logic works, etc. Anything
					you input will be not saved in reality, this is only for the master form testing.
				</p>
				<>
					<LoadingButton
						variant="contained"
						onClick={() => exportFormToPDF(formMetaInfo, null, applicationId, true)}
					>
						Print Form PDF
					</LoadingButton>
					<LoadingButton
						disabled={readOnlyTest}
						data-testid={'formBuilder-saveForm'}
						variant="contained"
						onClick={() => {
							const element = document.getElementById('testing-form-saveButton');
							element.click();
							setReadOnlyReset(false);
						}}
					>
						TEST FORM
					</LoadingButton>
					<Tooltip title={readOnlyReset ? `Nothing to reset` : ``} placement="top" arrow>
						<span>
							<LoadingButton
								disabled={readOnlyReset}
								variant="outlined"
								loading={loadingCancel}
								onClick={() => {
									setAlertBalloon({ isOpen: false });
									setReadOnly(false);
									setReadOnlyTest(false);
									setLoadingCancel(true);
									setOuterLoadingCancel && setOuterLoadingCancel(true);
									dispatch(clearValues());
									setTimeout(function () {
										setReadOnlyReset(true);
										setLoadingCancel(false);
										setOuterLoadingCancel && setOuterLoadingCancel(false);
									}, 1000);
								}}
							>
								RESET
							</LoadingButton>
						</span>
					</Tooltip>
				</>

				{saveModal && (
					<SaveDialog
						isOpen={saveModal?.isOpen}
						isYesNo
						title={'Save Form'}
						loading={saveModal?.isLoading}
						isSuccess={saveModal?.isSuccess}
						isError={saveModal?.isError}
						handleClose={() => setSaveModal(false)}
						handleSave={() => setSaveModal({ isOpen: true, isSuccess: true })}
					/>
				)}
			</div>
		</>
	);
};
