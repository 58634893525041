import myxss from './myxss';

const ComponentLabel = ({ data, read_only, className = '' }) => {
	const hasRequiredLabel = data.hasOwnProperty('required') && data.required;
	const labelText = myxss.process(data.label);

	return (
		<label
			className={className}
			style={{
				visibility: data?.custom_options?.includes('hideLabel') ? 'hidden' : 'visible',
				overflow: 'hidden',
				color: read_only ? 'rgba(0, 0, 0, 0.38)' : ''
			}}
		>
			<span dangerouslySetInnerHTML={{ __html: labelText }} />
			{hasRequiredLabel && <span className="label-required badge badge-danger">Required</span>}
		</label>
	);
};

export default ComponentLabel;
