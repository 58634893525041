import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DragHandle from './component-drag-handle';
import { makeFieldCopy, makeGroupCopy } from '../../FormBuilder/utils';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

import { setCopy, deleteRelatedDependancies } from '../slice';
import CopyIcon from '../../../assets/customIcons/copyIcon';
import { checkOverflow, getElementTitle } from '../../../utils/utilFunctions';
import { isBefore } from '../../../utils/dateUtils';

const ComponentHeader = ({ mutable, data, parent, editModeOn, index, setAsChild, _onDestroy }) => {
	const dispatch = useDispatch();
	const reduxFormSchema = useSelector((state) => state.form.formSchema);
	const publishableAt = useSelector((state) => state.form.applicationProcess?.publishableAt);
	const style = { width: '30px', height: '30px' };
	const noEdit = ['TwoColumnRow', 'ThreeColumnRow', 'MultiColumnRow'];
	const [openTooltip, setOpenTooltip] = useState(0);

	const removeAllActions = data.custom_options?.includes('not-editable');

	const isCreatedBeforeFinalized = isBefore(data.created_at, publishableAt);

	const editDisabled =
		isCreatedBeforeFinalized ||
		(data.element !== 'LineBreak' &&
			data.key !== 'MUI_GroupingEnd' &&
			noEdit?.includes(data.element));

	const deleteDisabled = data.created_at != null && publishableAt != null;

	const copyDisabled = noEdit?.includes(data.element) && data.key !== 'MUI_GroupingEnd';

	const elementTitle = getElementTitle(data);

	return mutable ? (
		<></>
	) : (
		<div>
			{data.pageBreakBefore && <div className="preview-page-break">Page Break</div>}

			<div className="toolbar-header">
				<span className="SortableItem-element-type">
					<Tooltip
						open={openTooltip === data?.field_name}
						onOpen={(e) => checkOverflow(e) && setOpenTooltip(data?.field_name)}
						onClose={() => setOpenTooltip(0)}
						arrow
						placement="top"
						title={elementTitle}
					>
						<p>{elementTitle}</p>
					</Tooltip>
				</span>

				<div className="toolbar-header-buttons">
					<Stack direction="row" spacing={0}>
						<IconButton
							data-testid={'formBuilder-editIcon'}
							style={style}
							aria-label="edit"
							onClick={editModeOn?.bind(parent, data)}
							disabled={removeAllActions || editDisabled}
						>
							<EditIcon
								sx={{ width: 'auto', height: '15px' }}
								color={removeAllActions || editDisabled ? 'action' : 'primary'}
							/>
						</IconButton>

						<IconButton
							style={style}
							data-testid={'formBuilder-copyButton'}
							aria-label="copy"
							disabled={removeAllActions || copyDisabled}
							onClick={() => {
								const copy =
									data?.key === 'MUI_GroupingStart'
										? makeGroupCopy(data, reduxFormSchema)
										: makeFieldCopy(data);

								dispatch(setCopy(copy));
							}}
						>
							<CopyIcon isActive={!removeAllActions && !copyDisabled} width={'15px'} />
						</IconButton>

						<IconButton
							data-testid={'formBuilder-deleteButton'}
							style={style}
							aria-label="delete"
							color="error"
							disabled={removeAllActions || deleteDisabled}
							onClick={() => {
								dispatch(deleteRelatedDependancies([reduxFormSchema, data]));
								_onDestroy(data);
							}}
						>
							<DeleteIcon
								sx={{ width: 'auto', height: '15px' }}
								color={removeAllActions || deleteDisabled ? 'action' : 'error'}
							/>
						</IconButton>
						<IconButton
							style={{
								...style,
								pointerEvents: removeAllActions && 'none',
								cursor: removeAllActions ? 'default' : 'move'
							}}
							aria-label="drag"
						>
							<DragHandle
								disabled={removeAllActions}
								data={data}
								index={index}
								onDestroy={_onDestroy}
								setAsChild={setAsChild}
							/>
						</IconButton>
					</Stack>
				</div>
			</div>
		</div>
	);
};

export default ComponentHeader;
